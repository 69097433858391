import { ArrowLeftOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Alert, Button, Input, Typography } from "antd";
import axios from "axios";
import qs from "qs";
import React, { useState } from "react";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { extractLinkParamsFromState, getBackendUrl, STORES } from "../utils";

const { Link } = Typography;

type Props = {
  clientSecret: string;
  onBack: () => any;
  onCancel: () => any;
  onComplete: any;
};

function Magento(props: Props) {
  const { onComplete, clientSecret } = props;

  const stateStore = Store.useStore();
  const isSandbox = stateStore.get("isSandbox");
  const organizationId = stateStore.get("orgId");
  const nonce = stateStore.get("nonce");
  const origin = stateStore.get("origin");
  const itemId = stateStore.get("itemId");
  const autoPlatform = stateStore.get("autoPlatform");

  const [url, setUrl] = useState(isSandbox ? "shop_good" : "");
  const [username, setUsername] = useState(isSandbox ? "user_good" : "");
  const [password, setPassword] = useState(isSandbox ? "pass_good" : "");
  const [otp, setOtp] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleAuthError = (e: any) => {
    captureException(e);
    if (e.response?.data?.error_message) {
      setError(e.response.data?.error_message);
    } else {
      setError(
        "An error occurred, but your credentials are secure. Please try again, or contact support."
      );
    }
  };

  const handleFakeComplete = () => {
    setLoading(true);
    axios
      .post(`${getBackendUrl()}/link/token/fulfill`, {
        type: STORES.MAGENTO,
        clientId: clientSecret,
        website: url,
        username: username,
        password: password,
      })
      .then((response) => {
        const { data } = response;
        const { public_token } = data;
        onComplete(public_token);
      })
      .catch(handleAuthError)
      .finally(() => {
        setLoading(false);
      });
  };
  
  const handleComplete = () => {
    setLoading(true);

    const query = qs.stringify({
      linkupdate: itemId ? itemId : undefined,
    });

    axios
      .post(`${getBackendUrl()}/magento/verify-credential?${query}`, {
        fullDomain: url,
        username: username,
        password: password,
        organizationId: organizationId,
        otp,
      })
      .then((response) => {
        const { data } = response;
        const { public_token } = data;
        onComplete(public_token);
      })
      .catch(handleAuthError)
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div style={{ padding: "1.6rem" }}>
      <div style={{ display: "flex" }} className="">
        {!autoPlatform && (
          <Button
            onClick={props.onBack}
            icon={<ArrowLeftOutlined />}
            className="flex items-center"
          >
            Back
          </Button>
        )}
      </div>
      <div
        className="flex items-center flex-column mt-4"
        style={{ justifyContent: "center", paddingBottom: "20px" }}
      >
        <img
          style={{ height: "80px" }}
          src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/magentologo.png"
          alt=""
        />
      </div>
      <div className="font-semibold">Full Domain</div>
      <div>
        Please provide full URL domain (EG: https://example.com/). Be sure to
        include http:// or https://.
      </div>
      <div style={{ marginBottom: 16 }} className="mt-2">
        <Input
          placeholder="https://example.com"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
      </div>
      <div className="font-semibold">Admin Username</div>
      <div style={{ marginBottom: 16 }} className="mt-2">
        <Input
          placeholder=""
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div className="font-semibold">Admin Password</div>
      <div style={{ marginBottom: 16 }} className="mt-2">
        <Input
          placeholder=""
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
        />
      </div>
      {showOtp ? (
        <>
          <div className="font-semibold">Auth Key</div>
          <div style={{ marginBottom:16, overflowWrap: "break-word" }}>
            Please provide an Auth Key from the Magento admin portal. You can
            find instructions on how to make an Auth key
            <b>
              <a
                href="https://www.notion.so/rutterapi/Magento-2-2FA-Reauth-5540979e89854023bb6dc8dc08728e35"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                here.
              </a>
            </b>
          </div>      
          <div className="font-semibold">Two-Factor Authentication Token</div>
          <div style={{ marginBottom: 16 }} className="mt-2">
            <Input
              placeholder=""
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
          </div>
        </>
      ) : (
        <>
        <Link
          onClick={() => setShowOtp(true)}
          className="flex items-center center text-center"
          style={{ marginBottom: 16 }}
        >
          Do you have Two-Factor Authentication enabled?
        </Link>
      </>
      )}

      {isSandbox ? (
        <Button
          type="primary"
          className=""
          block
          disabled={loading}
          onClick={handleFakeComplete}
        >
          {loading ? "Connecting..." : "Sandbox: Connect Test Magento"}
        </Button>
      ) : (
        <Button
          type="primary"
          block
          disabled={loading || url === "" || username === "" || password === ""}
          onClick={handleComplete}
        >
          {loading ? "Connecting..." : "Connect Magento"}
        </Button>
      )}
      {error && (
        <Alert
          className="mt-4"
          type="error"
          message={"Error"}
          description={error}
          showIcon
        ></Alert>
      )}
    </div>
  );
}

export default Magento;

const Container = styled.div``;
