import { ArrowLeftOutlined } from "@ant-design/icons";
import { Alert, Button, Select } from "antd";
import { useState } from "react";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { extractLinkParamsFromState, getBackendUrl, STORES } from "../utils";
import qs from "query-string";
import axios from "axios";
import TextUnderConnect, {
  shouldShowTextUnderConnect,
} from "./shared/TextUnderConnect";

export enum AMAZON_REGION {
  UNITED_STATES = "UNITED_STATES",
  CANADA = "CANADA",
  MEXICO = "MEXICO",
  BRAZIL = "BRAZIL",
  UNITED_KINGDOM = "UNITED_KINGDOM",
  GERMANY = "GERMANY",
  FRANCE = "FRANCE",
  SPAIN = "SPAIN",
  ITALY = "ITALY",
  NETHERLANDS = "NETHERLANDS",
  SWEDEN = "SWEDEN",
  JAPAN = "JAPAN",
  AUSTRALIA = "AUSTRALIA",
}

const AMAZON_REGION_HUMAN_READABLE = {
  UNITED_STATES: "United States",
  CANADA: "Canada",
  MEXICO: "Mexico",
  BRAZIL: "Brazil",
  UNITED_KINGDOM: "United Kingdom",
  GERMANY: "Germany",
  FRANCE: "France",
  SPAIN: "Spain",
  ITALY: "Italy",
  NETHERLANDS: "Netherlands",
  SWEDEN: "Sweden",
  JAPAN: "Japan",
  AUSTRALIA: "Australia",
};

type Props = {
  clientSecret: string;
  onBack: () => any;
  onCancel: () => any;
  onComplete: any;
};

function Amazon(props: Props) {
  const { clientSecret, onComplete } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [region, setRegion] = useState<string>("");
  const stateStore = Store.useStore();
  const isSandbox = stateStore.get("isSandbox");
  const autoPlatform = stateStore.get("autoPlatform");
  const organizationId = stateStore.get("orgId");
  const org = stateStore.get("organization");
  const nonce = stateStore.get("nonce");
  const origin = stateStore.get("origin");

  const isUpdate = stateStore.get("isUpdate");
  const itemId = stateStore.get("itemId");

  const handleFakeComplete = () => {
    setIsLoading(true);
    axios
      .post(`${getBackendUrl()}/link/token/fulfill`, {
        type: STORES.AMAZON,
        clientId: clientSecret,
        website: "shop_good",
      })
      .then((response) => {
        const { data } = response;
        const { public_token } = data;
        // NEED OT PASS THIS BACK SOMEHOW
        onComplete(public_token);
      })
      .catch(handleAuthError)
      .finally(() => {
        setIsLoading(false);
      });
  };
  const openAmazonAuth = () => {
    let result: any;
    setIsLoading(true);
    try {
      const q = qs.stringify({
        linkorigin: origin,
        linknonce: nonce,
        organizationId: organizationId,
        region: region,
        ...extractLinkParamsFromState(stateStore),
      });

      const url = `${getBackendUrl()}/amazon/link?${q}`;
      window.location.href = url;
    } catch (e) {
      handleAuthError(e);
      setIsLoading(false);
      return;
    }
  };

  const handleAuthError = (e: any) => {
    captureException(e);
    if (e.response?.data?.error_message) {
      setError(e.response.data?.error_message);
    } else {
      setError(
        "An error occurred, but your credentials are secure. Please try again, or contact support."
      );
    }
  };

  return (
    <div style={{ padding: "1.6rem" }}>
      <div style={{ display: "flex" }} className="">
        {!autoPlatform && (
          <Button
            onClick={props.onBack}
            icon={<ArrowLeftOutlined />}
            className="flex items-center"
          >
            Back
          </Button>
        )}
      </div>
      <div
        className="flex items-center flex-column mt-4"
        style={{ justifyContent: "center", paddingBottom: "20px" }}
      >
        <img
          style={{ height: "80px" }}
          src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/amazon-logo-square.jpg"
          alt=""
        />
      </div>
      <div className="font-semibold">Select your region</div>

      <Select
        showSearch
        style={{ width: "100%", marginTop: 20, marginBottom: 20 }}
        placeholder="Select region"
        onSelect={(val) => setRegion(val as string)}
      >
        {Object.keys(AMAZON_REGION).map((region) => (
          <Select.Option key={region} value={region}>
            {(AMAZON_REGION_HUMAN_READABLE as any)[region]}
          </Select.Option>
        ))}
      </Select>
      {isSandbox ? (
        <Button
          type="primary"
          block
          disabled={isLoading || !region}
          onClick={handleFakeComplete}
        >
          Connect Sandbox
        </Button>
      ) : (
        <Button
          type="primary"
          block
          disabled={isLoading || !region}
          onClick={openAmazonAuth}
        >
          Connect
        </Button>
      )}
      {shouldShowTextUnderConnect(org) && (
        <div className="mt-2 text-xs">
          <TextUnderConnect></TextUnderConnect>
        </div>
      )}
      {error && (
        <Alert
          className="mt-4"
          type="error"
          message={"Error"}
          description={error}
          showIcon
        ></Alert>
      )}
    </div>
  );
}

export default Amazon;
