import styled from "@emotion/styled";
import { Button } from "antd";
import qs from "qs";
import React, { useState } from "react";
import { FiCheck } from "react-icons/fi";
import { Route, Switch, useHistory } from "react-router-dom";
import BigCommerceAccount from "./bigcommerce/APIAccount";
import RutterLogo from "./RutterLogo";
import { captureException } from "./sentry";
import ShopifyPrivate from "./shopify/PrivateApp";
import Store from "./stateStore";
import Amazon from "./stores/Amazon";
import BigCommerce from "./stores/BigCommerce";
import Ebay from "./stores/Ebay";
import Ecwid from "./stores/Ecwid";
import Etsy from "./stores/Etsy";
import ShoperAuth from "./stores/Shoper";
import Magento from "./stores/Magento";
import PrestaShop from "./stores/PrestaShop";
import Shopify from "./stores/Shopify";
import Square from "./stores/Square";
import Squarespace from "./stores/Squarespace";
import Wix from "./stores/Wix";
import WooCommerce from "./stores/WooCommerce";
import StripeAuth from "./stores/Stripe";
import PaypalAuth from "./stores/Paypal";
import { STORES } from "./utils";
import url from "url-parse";

const HoverBackground = styled.div`
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
  }
`;

type LinkProps = {
  customerName: string;
  customerAction: string;
  origin: string;
  nonce: string;
  clientSecret: string;
  organizationId: string;
  isReconnect?: boolean;
  onSuccess: (token: string) => any;
  onError: (error: string) => any;
  onCancel: () => any;
  platform?: STORES;
};

export function buildCustomRedirectUrl(
  customRedirectUrl: string,
  queryParams: object
) {
  try {
    const parsedUrl = new url(customRedirectUrl, {}, true);
    if (parsedUrl.query) {
      return `${parsedUrl.origin?.includes("https") ? "" : "https://"}${
        parsedUrl.origin === "null" ? "" : parsedUrl.origin
      }${parsedUrl.pathname}?${qs.stringify({
        ...(parsedUrl.query as any),
        ...queryParams,
      })}`;
    } else {
      return `${
        parsedUrl.origin?.includes("https") ? "" : "https://"
      }${customRedirectUrl}?${qs.stringify({
        ...queryParams,
      })}`;
    }
  } catch (e) {
    throw e;
  }
}

function RutterLinkWrapper(props: LinkProps) {
  return <RutterLink {...props} />;
}

function RutterLink(props: LinkProps) {
  const { clientSecret } = props;
  const [store, setStore] = useState((props.platform as STORES) || "");
  let stateStore = Store.useStore();
  const platforms = stateStore.get("platforms");
  const origin = stateStore.get("origin");
  const org = stateStore.get("organization");
  const nonce = stateStore.get("nonce");
  const history = useHistory();
  const customUrl = stateStore.get("customRedirectUrl");

  const autoPlatform = stateStore.get("autoPlatform");

  const organization = stateStore.get("organization");

  React.useEffect(() => {
    if (autoPlatform) {
      switch (autoPlatform.toLowerCase()) {
        case STORES.SHOPIFY:
        case "shopify":
          history.push("/shopify");
          break;
        case "ecwid":
          history.push("/ecwid");
          break;
        case "etsy":
        case STORES.ETSY:
          history.push("/etsy");
          break;
        case "bigcommerce":
        case "big_commerce":
        case STORES.BIG_COMMERCE:
          history.push("/bigcommerce");
          break;
        case "square":
        case STORES.SQUARE:
          history.push("/square");
          break;
        case "squarespace":
        case STORES.SQUARESPACE:
          history.push("/squarespace");
          break;
        case "wix":
        case STORES.WIX:
          history.push("/wix");
          break;
        case "magento":
        case STORES.MAGENTO:
          history.push("/magento");
          break;
        case "woocommerce":
        case "woo_commerce":
        case STORES.WOO_COMMERCE:
          history.push("/woocommerce");
          break;
        case "amazon":
        case STORES.AMAZON:
          history.push("/amazon");
          break;
        case "ebay":
        case STORES.EBAY:
          history.push("/ebay");
          break;
        case "prestashop":
        case STORES.PRESTASHOP:
          history.push("/prestashop");
          break;
        case "stripe":
        case STORES.STRIPE:
          history.push("/stripe");
          break;
        case "paypal":
        case STORES.PAYPAL:
          history.push("/paypal");
          break;
        case STORES.SHOPER:
        case "shoper":
          history.push("/shoper");
          break;
        default:
          history.push("/platforms");
      }
    }
  }, [autoPlatform, history]);

  const closePlaid = () => {
    try {
      // post a message to cross-domain origin...
      if (window.opener) {
        window.opener.postMessage(
          JSON.stringify({
            nonce,
            type: "EXIT",
          }),
          origin
        );
      }
    } catch (e) {
      captureException(e);
    }
    props.onCancel();
  };
  const goBack = () => {
    history.goBack();
    // setStep(step - 1);
  };

  const completeAuth = (publicToken: string) => {
    // cancel iframe gtfo out of here
    try {
      // post a message to cross-domain origin, this is if Link is in a popup
      if (window.opener) {
        window.opener.postMessage(
          JSON.stringify({
            publicToken,
            nonce,
            type: "SUCCESS",
          }),
          origin
        );
      }
      // Now we want to send to the success screen, or custom URL if provided... with the public token
      if (customUrl) {
        const redirect = buildCustomRedirectUrl(customUrl, {
          public_token: publicToken,
        });
        window.location.replace(redirect);
      } else {
        // go to link success screen
        history.push("/complete");
      }
    } catch (e) {
      captureException(e);
      history.push("/error");
    }
  };

  const { linkLogoUrl, linkText } = organization || {};

  const renderStep = (currentStep: number) => {
    if (currentStep === 0) {
      return (
        <div
          style={{ padding: "3.2rem", height: "100%", position: "relative" }}
        >
          <div className="flex flex-column items-center justify-center pb-4">
            {linkLogoUrl ? (
              <img
                style={{ height: 48, marginBottom: 20 }}
                src={linkLogoUrl}
                alt=""
              />
            ) : (
              <div style={{ width: 48, height: 48, marginBottom: 20 }}>
                <RutterLogo />
              </div>
            )}
          </div>
          <div className="text-xl text-center">
            {linkText ? (
              <div className="text-center">{linkText}</div>
            ) : (
              <>
                <span className="font-semibold">{props.customerName}</span> uses{" "}
                <span className="font-semibold">Rutter</span>&nbsp;to{"\n"}
                <div className="text-center">{props.customerAction}</div>
              </>
            )}
          </div>
          <div className="mt-8">
            <div>
              <div className="flex items-center">
                <FiCheck size={30} color="#62DAFF"></FiCheck>
                <div>
                  <div className="ml-3 text-lg">Secure</div>
                  <div className="ml-3">
                    Your data is handled securely with end-to-end encryption.
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <div className="flex items-center">
                <FiCheck size={30} color="#62DAFF"></FiCheck>
                <div>
                  <div className="ml-3 text-lg">Private</div>
                  <div className="ml-3">
                    Your data will never be shared without your permission.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: "42px",
              marginBottom: ".8rem",
              marginRight: ".8rem",
              marginLeft: ".8rem",
            }}
            className=""
          >
            <Button
              onClick={() => {
                history.push("/platforms");
              }}
              style={{ marginTop: "auto" }}
              className="w-full"
              type="primary"
              size="large"
            >
              Continue
            </Button>
            <div
              className="text-center pt-2"
              style={{ fontSize: "0.8rem", color: "#949494" }}
            >
              By selecting "Continue" you agree to the{" "}
              <a
                target="_blank"
                href="https://rutterdocs.s3.us-east-2.amazonaws.com/End-user+Privacy+Policy.pdf"
                rel="noreferrer"
              >
                Rutter End User Privacy Policy
              </a>
              {org?.additionalPrivacyPolicyLink && (
                <span>
                  {" "}
                  & the{" "}
                  <a
                    target="_blank"
                    href={org.additionalPrivacyPolicyLink}
                    rel="noreferrer"
                  >
                    {org.name} Privacy Policy
                  </a>
                </span>
              )}
            </div>
          </div>
        </div>
      );
    } else if (currentStep === 1) {
      return (
        <>
          <div
            style={{
              marginTop: ".8rem",
              marginRight: ".8rem",
              marginLeft: ".8rem",
            }}
          >
            {/* <div style={{ display: "flex" }} className="justify-between">
              <FiChevronLeft
                style={{ cursor: "pointer" }}
                onClick={goBack}
              ></FiChevronLeft>
              <FiX style={{ cursor: "pointer" }} onClick={closePlaid}></FiX>
            </div> */}

            <div className="pt-4 text-lg font-bold">Select your platform</div>
          </div>
          {/* in futre we need search */}

          <div className="mt-2">
            {platforms.map((platform: any) => {
              return (
                <HoverBackground
                  key={platform.id}
                  onClick={() => {
                    // setStep((prev) => prev + 1);
                    if (platform.name === "Shopify") {
                      setStore(STORES.SHOPIFY);
                      history.push("/shopify");
                    } else if (platform.name === "Ecwid") {
                      setStore(STORES.ECWID);
                      history.push("/ecwid");
                    } else if (platform.name === "BigCommerce") {
                      setStore(STORES.BIG_COMMERCE);
                      history.push("/bigcommerce");
                    } else if (platform.name === "WooCommerce") {
                      setStore(STORES.WOO_COMMERCE);
                      history.push("/woocommerce");
                    } else if (platform.name === "Squarespace") {
                      history.push("/squarespace");
                    } else if (platform.name === "Wix") {
                      setStore(STORES.WIX);
                      history.push("/wix");
                    } else if (platform.name === "Magento") {
                      setStore(STORES.MAGENTO);
                      history.push("/magento");
                    } else if (platform.name === "Amazon") {
                      setStore(STORES.AMAZON);
                      history.push("/amazon");
                    } else if (platform.name === "Square") {
                      setStore(STORES.SQUARE);
                      history.push("/square");
                    } else if (platform.name === "Ebay") {
                      setStore(STORES.EBAY);
                      history.push("/ebay");
                    } else if (platform.name === "Etsy") {
                      history.push("/etsy");
                    } else if (
                      platform.name === "PrestaShop" ||
                      platform.name === "Prestashop"
                    ) {
                      history.push("/prestashop");
                    } else if (platform.name === "Stripe") {
                      history.push("/stripe");
                    } else if (platform.name === "Paypal") {
                      history.push("/paypal");
                    } else if (platform.name === "Shoper") {
                      history.push("/shoper");
                    } else {
                      history.replace(
                        "/error?" +
                          qs.stringify({
                            error_message:
                              "Platform not configured correctly. Please contact support@rutterapi.com for help.",
                          })
                      );
                    }
                  }}
                  className="py-4 flex items-center"
                  style={{ borderBottom: "1px solid #F2F2F2" }}
                >
                  <div>
                    <img
                      style={{ marginLeft: "1.6rem", height: "40px" }}
                      src={platform.logoUrl}
                      alt=""
                    />
                  </div>
                  <div className="ml-3">{platform.name}</div>
                </HoverBackground>
              );
            })}
          </div>
        </>
      );
    } else if (currentStep === 2) {
      // Note:  This is tech debt.  Move to using the Route/Switch below instead.
      switch (store) {
        case STORES.SHOPIFY:
          return (
            <Shopify
              clientSecret={clientSecret}
              onBack={goBack}
              onCancel={closePlaid}
              onComplete={completeAuth}
            />
          );
        case STORES.MAGENTO:
          return (
            <Magento
              clientSecret={clientSecret}
              onBack={goBack}
              onCancel={closePlaid}
              onComplete={completeAuth}
            />
          );
        case STORES.WOO_COMMERCE:
          return (
            <WooCommerce
              clientSecret={clientSecret}
              onBack={goBack}
              onCancel={closePlaid}
              onComplete={completeAuth}
            />
          );
        case STORES.BIG_COMMERCE:
          return (
            <BigCommerce
              clientSecret={clientSecret}
              onBack={goBack}
              onCancel={closePlaid}
              onComplete={completeAuth}
            />
          );
        case STORES.WIX:
          return (
            <Wix
              clientSecret={clientSecret}
              onBack={goBack}
              onCancel={closePlaid}
              onComplete={completeAuth}
            />
          );
        case STORES.SQUARESPACE:
          return (
            <Squarespace
              clientSecret={clientSecret}
              onBack={goBack}
              onCancel={closePlaid}
              onComplete={completeAuth}
            />
          );
        case STORES.SQUARE:
          return (
            <Square
              clientSecret={clientSecret}
              onBack={goBack}
              onCancel={closePlaid}
              onComplete={completeAuth}
            />
          );
        case STORES.AMAZON:
          return (
            <Amazon
              clientSecret={clientSecret}
              onBack={goBack}
              onCancel={closePlaid}
              onComplete={completeAuth}
            />
          );
        case STORES.EBAY:
          return (
            <Ebay
              clientSecret={clientSecret}
              onBack={goBack}
              onCancel={closePlaid}
              onComplete={completeAuth}
            />
          );
        default:
          return <div />;
      }
    } else {
      return null;
    }
  };

  return (
    <Switch>
      <Route exact path="/bigcommerceapiaccount">
        <BigCommerceAccount
          clientSecret={clientSecret}
          onBack={goBack}
          onCancel={closePlaid}
          onComplete={completeAuth}
        ></BigCommerceAccount>
      </Route>
      <Route exact path="/shopifyprivate">
        <ShopifyPrivate
          clientSecret={clientSecret}
          onBack={goBack}
          onCancel={closePlaid}
          onComplete={completeAuth}
        ></ShopifyPrivate>
      </Route>
      <Route>
        <div
          style={{
            height: 608,
            width: org?.whitelabelOptions?.maxWidth
              ? org?.whitelabelOptions?.maxWidth
              : 360,
            backgroundColor: "white",
            borderRadius: 8,
            overflowY: "auto",
          }}
        >
          <Switch>
            <Route exact path={`/platforms`}>
              {renderStep(1)}
            </Route>
            <Route exact path="/auth">
              {renderStep(2)}
            </Route>
            <Route exact path="/ecwid">
              <Ecwid
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/shopify">
              <Shopify
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/squarespace">
              <Squarespace
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/magento">
              <Magento
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/woocommerce">
              <WooCommerce
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/bigcommerce">
              <BigCommerce
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/wix">
              <Wix
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/square">
              <Square
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/amazon">
              <Amazon
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/ebay">
              <Ebay
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/etsy">
              <Etsy
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/prestashop">
              <PrestaShop
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/stripe">
              <StripeAuth
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/paypal">
              <PaypalAuth
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/shoper">
              <ShoperAuth
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route>{renderStep(0)}</Route>
          </Switch>
        </div>
      </Route>
    </Switch>
  );
}

export default RutterLinkWrapper;
