import { ArrowLeftOutlined } from "@ant-design/icons";
import { Alert, Button, Input } from "antd";
import axios from "axios";
import * as qs from "qs";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { extractLinkParamsFromState, getBackendUrl, STORES } from "../utils";
import PlatformPageText from "./shared/PlatformPageText";
import TextUnderConnect, {
  shouldShowTextUnderConnect,
} from "./shared/TextUnderConnect";

type Props = {
  clientSecret: string;
  onBack: () => any;
  onCancel: () => any;
  onComplete: (public_token: string) => any;
};

function Shopify(props: Props) {
  const { onComplete, clientSecret } = props;

  const [loading, setIsLoading] = React.useState(false);
  const [isRedirecting, setIsRedirecting] = React.useState(false);
  const [error, setError] = useState("");
  const stateStore = Store.useStore();
  const isSandbox = stateStore.get("isSandbox");
  const organizationId = stateStore.get("orgId");
  const org = stateStore.get("organization");
  const nonce = stateStore.get("nonce");
  const origin = stateStore.get("origin");
  const history = useHistory();
  const isUpdate = stateStore.get("isUpdate");
  const autoPlatform = stateStore.get("autoPlatform");
  const itemId = stateStore.get("itemId");
  const autoStoreName = stateStore.get("autoShopifyStore");
  const [website, setWebsite] = useState(autoStoreName ? autoStoreName : "");

  const handleAuthError = (e: any) => {
    captureException(e);
    if (e.response?.data?.error_message) {
      setError(e.response.data?.error_message);
    } else {
      setError(
        "An error occurred, but your credentials are secure. Please try again, or contact support."
      );
    }
  };

  const handleComplete = async () => {
    // validate this
    if (website.endsWith(".")) {
      setError("Please remove the trailing dot at the end of the store name");
      return;
    }
    if (website.startsWith("http")) {
      setError(
        "Please only input the store name and remove the http:// or https:// at the front"
      );
      return;
    }
    if (website.includes("myshopify.com")) {
      setError("Please only input the store name and remove .myshopify.com");
      return;
    }

    const store = `${website}.myshopify.com`;
    if (org?.isPrivateShopify) {
      stateStore.set("shopifyStoreUrl")(store);
      history.push("/shopifyprivate");
      return;
    }
    setIsLoading(true);
    let result;
    try {
      const url = `${getBackendUrl()}/shopify/link/${organizationId}/generate-url`;
      result = await axios.get(url, {
        params: {
          store,
        },
      });
    } catch (e) {
      handleAuthError(e);
      setIsLoading(false);
      return;
    }
    const { type, url } = result.data;
    const query = qs.stringify({
      shop: store,
      linkorigin: origin,
      linknonce: nonce,
      linkupdate: isUpdate ? itemId : undefined,
      ...extractLinkParamsFromState(stateStore),
    });
    let redirectUrl;
    if (type === "PROXY") {
      redirectUrl = `${url}?${query}`;
    } else {
      redirectUrl = url;
      // also need to save some data to localstorage probably
      localStorage.setItem(
        "rutterlinkstate",
        JSON.stringify({
          currentPlatform: "SHOPIFY",
          ...extractLinkParamsFromState(stateStore),
        })
      );
    }
    setIsRedirecting(true);
    window.location.replace(redirectUrl);
  };

  const handleFakeComplete = () => {
    setIsLoading(true);
    axios
      .post(`${getBackendUrl()}/link/token/fulfill`, {
        type: STORES.SHOPIFY,
        clientId: clientSecret,
        website: "shop_good",
      })
      .then((response) => {
        const { data } = response;
        const { public_token } = data;
        // NEED OT PASS THIS BACK SOMEHOW
        onComplete(public_token);
      })
      .catch(handleAuthError)
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div style={{ padding: "1.6rem" }}>
      <div style={{ display: "flex" }} className="">
        {!autoPlatform && (
          <Button
            onClick={props.onBack}
            icon={<ArrowLeftOutlined />}
            className="flex items-center"
          >
            Back
          </Button>
        )}
      </div>
      <div
        className="flex items-center flex-column mt-4"
        style={{ justifyContent: "center", paddingBottom: "20px" }}
      >
        <img
          style={{ height: "80px" }}
          src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/shopifylogo.png"
          alt=""
        />
      </div>

      {org?.platformPageText && (
        <div className="mb-2 text-xs">
          <PlatformPageText></PlatformPageText>
        </div>
      )}

      <div className="font-semibold">Store URL</div>
      <div className="text-sm">
        Please enter in your custom Shopify subdomain. If your subdomain is
        example.shopify.com, then enter in "example".
      </div>
      <div style={{ marginBottom: 16 }} className="mt-2">
        <Input
          addonBefore="http://"
          addonAfter=".myshopify.com"
          placeholder="Store Name"
          value={website}
          onChange={(e) => setWebsite(e.target.value)}
        />
      </div>
      {isSandbox ? (
        <Button
          type="primary"
          className=""
          block
          disabled={loading}
          onClick={handleFakeComplete}
        >
          {loading ? "Connecting..." : "Sandbox: Connect Test Shopify"}
        </Button>
      ) : (
        <Button
          type="primary"
          block
          disabled={loading || website === ""}
          onClick={handleComplete}
        >
          {loading
            ? isRedirecting
              ? "Redirecting to Shopify, please wait..."
              : "Connecting..."
            : "Connect Shopify"}
        </Button>
      )}

      {shouldShowTextUnderConnect(org) && (
        <div className="mt-2 text-xs">
          <TextUnderConnect></TextUnderConnect>
        </div>
      )}

      {error && (
        <Alert
          className="mt-4"
          type="error"
          message={"Error"}
          description={error}
          showIcon
        ></Alert>
      )}
    </div>
  );
}

export default Shopify;
