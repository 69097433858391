import { Alert, Spin } from "antd";
import axios from "axios";
import qs from "query-string";
import React from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { getBackendUrl } from "../utils";

function LinkContainer() {
  const history = useHistory();
  const { search } = history.location;
  const parsedSearch = qs.parse(search);
  let store = Store.useStore();
  const [loading, setLoading] = React.useState(false);
  const [orgName, setOrgName] = React.useState("This app");
  const [orgId, setOrgId] = React.useState("");
  const [error, setError] = React.useState("");
  const params = useParams();
  const { id } = params as any;
  const { platform, shopifyStore, redirect_url } = parsedSearch;

  React.useEffect(() => {
    // effect run once
    setLoading(true);

    axios
      .get(`${getBackendUrl()}/link/initupdate`, {
        params: {
          itemId: id,
        },
      })
      .then((response) => {
        const { data } = response;
        const { token } = data;

        const query = qs.stringify({
          token,
          itemid: id,
        });
        store.set("isUpdate")(true);
        store.set("itemId")(id);
        if (platform) {
          store.set("autoPlatform")(platform as any);
        }
        if (shopifyStore) {
          store.set("autoShopifyStore")(shopifyStore as string);
        }
        if (redirect_url) {
          store.set("customRedirectUrl")(redirect_url as string);
        }
        history.push(`/?${query}`);
        // do local storage...
      })
      .catch((e) => {
        captureException(e);
        if (e.message === "Network Error") {
          // user/dev
          setError("Error connecting to Rutter. Please try again later.");
        } else if (e?.response?.data?.error === "INVALID_PUBLIC_KEY") {
          // dev
          setError(
            "Invalid Rutter public key. To find your public key, visit https://dashboard.rutterapi.com"
          );
        } else if (
          e?.response?.data?.error_message === "Invalid connection url"
        ) {
          setError("This connection does not exist.");
        } else {
          setError(
            "An unexpected error occurred, and we're looking into it. Please try again later."
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 99,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin></Spin>
      </div>
    );
  }

  if (error) {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 99,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Alert
          type="error"
          message={"Error"}
          description={error}
          showIcon
        ></Alert>
      </div>
    );
  }

  // should never end up here
  return (
    <>
      <Switch>
        <Route path="/">
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 99,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {error}
          </div>
        </Route>
      </Switch>
    </>
  );
}

export default LinkContainer;
