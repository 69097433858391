import { ArrowLeftOutlined } from "@ant-design/icons";
import { Alert, Button, Form, Input, Steps, Typography } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { extractLinkParamsFromState, getBackendUrl } from "../utils";

const { Text, Link, Title, Paragraph } = Typography;

const { Step } = Steps;

type Props = {
  clientSecret: string;
  onBack: () => any;
  onCancel: () => any;
  onComplete: (public_token: string) => any;
};

function Shopify(props: Props) {
  const { onComplete } = props;

  const [loading, setIsLoading] = React.useState(false);
  const [website, setWebsite] = useState("");
  const [error, setError] = useState("");
  const stateStore = Store.useStore();
  const isSandbox = stateStore.get("isSandbox");
  const organizationId = stateStore.get("orgId");
  const organizationName = stateStore.get("orgName");
  const shopifyStoreUrl = stateStore.get("shopifyStoreUrl");
  const nonce = stateStore.get("nonce");
  const origin = stateStore.get("origin");
  const autoPlatform = stateStore.get("autoPlatform");
  const publicKey = stateStore.get("publicKey");
  const org = stateStore.get("organization");
  const [showPrivate, setShowPrivate] = React.useState(false);
  const [form] = Form.useForm();
  const [windowOpened, setWindowOpened] = React.useState(false);

  const handleAuthError = (e: any) => {
    captureException(e);
    if (e.response?.data?.error_message) {
      setError(e.response.data?.error_message);
    } else {
      setError(
        "An error occurred, but your credentials are secure. Please try again, or contact support."
      );
    }
  };

  function myPopup(
    myURL: string,
    title: string,
    myWidth: number,
    myHeight: number
  ) {
    var left = (window.screen as any).width / 2;
    left = window.innerWidth;
    var top = 0;
    var myWindow = window.open(
      myURL,
      title,
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
        myWidth +
        ", height=" +
        myHeight +
        ", top=" +
        top +
        ", left=" +
        left
    );
    if (myWindow) {
      setWindowOpened(true);
      var pollTimer = window.setInterval(function () {
        if (myWindow?.closed !== false) {
          // !== is required for compatibility with Opera
          window.clearInterval(pollTimer);
          setWindowOpened(false);
        }
      }, 200);
    }
  }

  const handleFinish = (values: any) => {
    const { apiKey, password } = values;
    // try to post to backend
    setIsLoading(true);
    axios
      .post(`${getBackendUrl()}/shopify/shopifyprivate`, {
        apiKey,
        password,
        publicKey: publicKey,
        storeName: shopifyStoreUrl,
        ...extractLinkParamsFromState(stateStore),
      })
      .then((res) => {
        const { data } = res;
        const { public_token } = data;
        onComplete(public_token);
      })
      .catch((e) => {
        handleAuthError(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderDescriptionForAppDetails = () => {
    return (
      <div>
        <div className="flex items-align">
          <div>Private app name:</div>
          <div>
            <Text code copyable className="ml-2 items-center flex">
              {organizationName}
            </Text>
          </div>
        </div>
        <div className="flex items-align mt-1">
          <div>Emergency developer email:</div>
          <div>
            <Text code copyable className="ml-2 items-center flex">
              {org?.emergencyDeveloperEmail || "support@rutterapi.com"}
            </Text>
          </div>
        </div>
      </div>
    );
  };

  const renderDescriptionForPermissions = () => {
    const permissionsMapping: any = {};
    const scopes =
      org?.privateShopifyScopes || "read_products,read_orders,read_customers";
    const splitScopes = scopes.split(",");
    const READ_ACCESS = "Read access";
    const READ_WRITE_ACCESS = "Read and write";
    let unMapped: string[] = [];
    splitScopes.forEach((scope) => {
      switch (scope) {
        case "read_products":
          permissionsMapping["Products"] = READ_ACCESS;
          break;
        case "read_orders":
          permissionsMapping["Orders"] = READ_ACCESS;
          break;
        case "write_orders":
          permissionsMapping["Orders"] = READ_WRITE_ACCESS;
          break;
        case "read_customers":
          permissionsMapping["Customers"] = READ_ACCESS;
          break;
        case "write_customers":
          permissionsMapping["Customers"] = READ_WRITE_ACCESS;
          break;
        case "read_analytics":
          permissionsMapping["Analytics"] = READ_ACCESS;
          break;
        case "read_assigned_fulfillment_orders":
          permissionsMapping["Assigned fulfillment orders"] = READ_ACCESS;
          break;
        case "write_assigned_fulfillment_orders":
          permissionsMapping["Assigned fulfillment orders"] = READ_WRITE_ACCESS;
          break;
        case "read_inventory":
          permissionsMapping["Inventory"] = READ_ACCESS;
          break;
        case "write_inventory":
          permissionsMapping["Inventory"] = READ_WRITE_ACCESS;
          break;
        default:
          unMapped.push(scope);
          break;
      }
    });

    return (
      <div>
        {Object.keys(permissionsMapping).map((key) => {
          return (
            <div key={key} className="flex items-align">
              <div>{key}</div>
              <Text code className="ml-2 items-center flex">
                {permissionsMapping[key]}
              </Text>
            </div>
          );
        })}
        {unMapped.length > 0 && (
          <div className="flex items-align">
            <div>Additional:</div>
            {unMapped.map((scope) => {
              return (
                <Text code className="ml-1 items-center flex">
                  {scope}
                </Text>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const showEverything = windowOpened || form.isFieldsTouched();

  return (
    <div style={{ padding: "1.6rem 3rem 200px", minHeight: "100%" }}>
      <div style={{ display: "flex" }} className="">
        {!autoPlatform && (
          <Button
            onClick={props.onBack}
            icon={<ArrowLeftOutlined />}
            className="flex items-center"
          >
            Back
          </Button>
        )}
      </div>
      <div
        className="flex  flex-column"
        style={{ justifyContent: "center", paddingBottom: "20px" }}
      >
        <img
          style={{ height: "80px" }}
          src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/shopifylogo.png"
          alt=""
        />
      </div>
      <Alert
        message={
          <div>
            <b>{organizationName}</b> does not currently have an app in the app
            store. To share your data with {organizationName}, follow the steps
            below to create a Private App.
          </div>
        }
        type="info"
      />

      <Steps
        current={windowOpened ? 1 : 0}
        direction="vertical"
        className="mt-4"
      >
        <Step
          title={
            <Link
              onClick={() => {
                // depending on size of available screen, we may want to resize link widnow to make it smaller
                let width = window.screen.width - window.innerWidth;

                if (window.innerWidth === window.screen.width) {
                  width = window.innerWidth / 2;
                }
                myPopup(
                  `https://${shopifyStoreUrl}/admin/apps/private/new`,
                  "web",
                  width,
                  window.innerHeight
                );
              }}
            >
              Click here to go to your Private Apps page.
            </Link>
          }
          description="If you have not already, enable Private Apps."
        />
        {showEverything && (
          <>
            <Step title={`Create a new Private App.`} status="process" />
            <Step
              title={`Copy these values into App details.`}
              status="process"
              description={renderDescriptionForAppDetails()}
            />
            <Step
              title={
                <div>
                  Under <b>Admin API</b>, add these permissions:
                </div>
              }
              status="process"
              description={renderDescriptionForPermissions()}
            />
            <Step title={`Save the Private App.`} status="process" />
            <Step
              title={`Copy the API key and Password into the fields below:`}
              status="process"
            />
            <Form
              layout="vertical"
              form={form}
              style={{ paddingBottom: 40 }}
              onFinish={handleFinish}
            >
              <Form.Item label="Store URL" name="storeUrl" rules={[{}]}>
                <div>{shopifyStoreUrl}</div>
              </Form.Item>
              <Form.Item
                label="API Key"
                name="apiKey"
                rules={[{ required: true, message: "API key required" }]}
              >
                <Input placeholder="111114e91e382357bc61b5e7067edd39" />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: "Password required" }]}
              >
                <Input.Password placeholder="***********************" />
              </Form.Item>
              <Form.Item>
                {error && (
                  <Alert
                    className="mb-4"
                    type="error"
                    message={"Error"}
                    description={error}
                    showIcon
                  ></Alert>
                )}
                <Button type="primary" htmlType="submit" disabled={loading}>
                  {loading ? "Connecting..." : "Finish"}
                </Button>
              </Form.Item>
            </Form>
          </>
        )}
      </Steps>
    </div>
  );
}

export default Shopify;
